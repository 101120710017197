import React from 'react'
import { Link } from 'gatsby'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import WholesaleSidebar from 'components/WholesaleSidebar'

import s from './index.module.css'

export default function ApplicationIndex() {
  return (
    <Layout header="wholesale">
      <SEO
        title="Wholesale Account Sign Up Process | Chalk UK"
        description="Wholesale Sign Up Process. Become a stockist of Chalk's products."
      />
      <div className={s.container}>
        <WholesaleSidebar index />
        <div className={s.content}>
          <h1 className={s.title}>Chalk Wholesale Account Application</h1>
          <p className={s.description}>
            The application will take no more than <u>5 mins</u>
            <br />
            We are experiencing high levels of enquiries, your application will
            be added to a queue. We appreciate your patience.
            {/* We will follow up within a <u>couple of business days</u> */}
          </p>
          <Link to="/wholesale/application/start" className={s.link}>
            Start Application
          </Link>
        </div>
      </div>
    </Layout>
  )
}
